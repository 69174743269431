import { defineStore } from "pinia";
import { computed, ref, watch } from "vue";
import { jwtDecode } from "jwt-decode";
import { useAsyncState } from "@vueuse/core";
import * as sessionApi from "@/portal/api/session";

export const refreshTokenBuffer = 5 * 60 * 1000; // 5 * 60 * 1000 ms is 5 minutes.

export const useSessionStore = defineStore("session", () => {
  let refreshTokenTimeout: number | undefined = undefined;

  const queryTokenRef = ref<string | undefined>();
  const queryToken = computed({
    get: () => queryTokenRef.value,
    set: (value) => {
      if (value) {
        try {
          jwtDecode(value, { header: false });
        } catch (error) {
          if (import.meta.env.DEV) console.log('[session] invalid query token', value, String(error));

          value = undefined
        }
      }
      queryTokenRef.value = value;
    }
  })

  const {
    state: profile,
    execute: fetchProfileExecute,
    isLoading: fetchProfileLoading,
    error: fetchProfileError,
  } = useAsyncState(
    async (profileId: string) => {
      const result = await sessionApi.fetchProfile({ profileId })
      if (result.success) return result.data

      throw result.error || new Error("Failed to fetch profile")
    },
    undefined,
    { immediate: false, throwError: true },
  );

  const {
    state: serverToken,
    execute: fetchServerTokenExecute,
    isLoading: fetchServerTokenLoading,
    error: fetchServerTokenError,
  } = useAsyncState(
    async (customerId: string) => {
      const result = await sessionApi.createToken({ customerId })
      if (result.success) return result.data.token

      throw result.error || new Error("Failed to fetch token")
    },
    undefined,
    { immediate: false },
  );

  const {
    execute: initializeExecute,
    isLoading: initializeLoading,
    error: initializeError,
  } = useAsyncState(
    async (profileId: string) => {
      const profile = await fetchProfileExecute(undefined, profileId)
      if (profile) {
        return fetchServerTokenExecute(undefined, profile.customer.id)
      }
    },
    undefined,
    { immediate: false },
  );

  const initialize = async (profileId: string) => initializeExecute(undefined, profileId)

  // TODO: Remove this.
  const queryTokenDecoded = computed(() => {
    if (!queryToken.value) return undefined;

    try {
      return jwtDecode(queryToken.value, { header: false });
    } catch (error) {
      return undefined;
    }
  });

  // TODO: Remove this.
  const serverTokenDecoded = computed(() => {
    if (!serverToken.value) return undefined;

    try {
      return jwtDecode(serverToken.value, { header: false });
    } catch (error) {
      return undefined;
    }
  });

  const customer = computed(() => profile.value?.customer);
  const customerId = computed(() => customer.value?.id);
  const token = computed(() => queryToken.value || serverToken.value);
  const tokenDecoded = computed(() => queryTokenDecoded.value || serverTokenDecoded.value);
  const authenticated = computed(() => !!token.value);

  const refreshToken = async () => {
    queryToken.value = undefined;
    clearTimeout(refreshTokenTimeout);
    if (customerId.value) {
      if (import.meta.env.DEV) console.log('[session] refresh server token (on demand)');

      await fetchServerTokenExecute(undefined, customerId.value);
    }
  }

  // Remove expired query token.
  watch(queryTokenDecoded, (payload) => {
    if (import.meta.env.DEV) console.log('[session] query token', payload);

    // The "exp" claim us the "expiration time" (in seconds since the Unix epoch).
    // `Date.now()` returns the number of milliseconds elapsed since the Unix epoch.
    if (payload?.exp && payload?.exp * 1000 < Date.now()) {
      if (import.meta.env.DEV) {
        console.log('[session] query token expired');
      }
      queryToken.value = undefined;
    }
  }, { deep: true });

  // Refresh the server token before it expires.
  watch(serverTokenDecoded, (payload) => {
    if (import.meta.env.DEV) console.log('[session] server token', payload);

    clearTimeout(refreshTokenTimeout);
    if (payload?.exp) {
      // The "exp" claim us the "expiration time" (in seconds since the Unix epoch).
      // `Date.now()` returns the number of milliseconds elapsed since the Unix epoch.
      const expirationDelay = payload.exp * 1000 - Date.now();
      const refreshDelay = expirationDelay - refreshTokenBuffer;
      refreshTokenTimeout = setTimeout(() => {
        if (customerId.value) {
          if (import.meta.env.DEV) console.log('[session] refresh server token (expires soon)');

          fetchServerTokenExecute(undefined, customerId.value);
        }
      }, refreshDelay);
    }
  }, { deep: true });

  return {
    // Initialize
    initialize,
    initializeLoading,
    initializeError,
    // Profile
    profile,
    customer,
    customerId,
    fetchProfileLoading,
    fetchProfileError,
    // Query token
    queryToken,
    queryTokenDecoded,
    // Server token
    serverToken,
    serverTokenDecoded,
    fetchServerTokenError,
    fetchServerTokenLoading,
    // Token
    token,
    tokenDecoded,
    authenticated,
    refreshToken,
  };
});
