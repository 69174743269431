import path from 'path'
import { createI18n, type I18nOptions } from 'vue-i18n'
import { has, isEmpty, set, mergeWith } from 'lodash'

const options: I18nOptions = {
  allowComposition: true,
  fallbackLocale: 'en',
  messages: {}
}

// Automatically load files in `./locales` directory.
// 1. Load locales from files.
const files: { filepath: string; locale_name: string; messages: string }[] = []
const context = import.meta.glob('@/portal/i18n/*.json', { eager: true })

Object.keys(context).forEach((filepath) => {
  const locale_name = path.basename(filepath, '.json')
  const filepathWithoutBase = filepath.replace('/portal/i18n/', '')
  const keys = path.dirname(filepathWithoutBase).split(path.sep).filter((key: string) => key !== '.')
  const localeModule = context[filepath]
  const locales = (localeModule as any).default /* eslint-disable-line @typescript-eslint/no-explicit-any */
  const messages = isEmpty(keys) ? locales : set({}, keys, locales)

  files.push({ filepath, locale_name, messages })
})

// 2. Merge locales.
files.forEach(({ locale_name, messages }) => {
  // Set default locale name value.
  if (!has(options.messages, locale_name)) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    options.messages![locale_name] = {}
  }

  mergeWith(options.messages![locale_name], messages)
})

const i18n = createI18n(options)

export { i18n }
export default i18n.global
