/* eslint-disable @typescript-eslint/no-explicit-any */

import z from "zod";

export class ApiParseError extends Error {
  public name = "ApiParseError";
  public message = "Failed to parse response";
  public url: string;
  public method: string;
  public data: unknown;
  public error: z.ZodError<any>;

  constructor(options: {
    method: string;
    url: string;
    data: unknown;
    error: z.ZodError<any>;
  }) {
    const { method, url, data, error } = options;
    super(`Failed to parse response for request ${method} ${url}`);
    this.url = url;
    this.method = method;
    this.data = data;
    this.error = error;

    Object.setPrototypeOf(this, ApiParseError.prototype);
  }
}

export type RequestResult<T> =
  | {
      success: true;
      data: T;
      response: Response;
      error?: undefined;
    }
  | {
      success: false;
      data?: undefined;
      response?: Response;
      error: unknown;
    };

export function request<const Schema extends z.ZodSchema<any>>( url: string, options: RequestInit, schema: Schema): Promise<RequestResult<z.infer<Schema>>>;
export function request(url: string, options: RequestInit): Promise<RequestResult<never>>;
export async function request(
  url: string,
  options: RequestInit,
  schema?: z.ZodSchema<any>,
): Promise<RequestResult<any>> {
  let response: Response;
  try {
    response = await fetch(url, options);
  } catch (error) {
    return { success: false, error };
  }

  if (!response.ok) {
    return {
      success: false,
      error: new Error("Failed to fetch data"),
      response,
    };
  }

  let data
  try {
    data = await response.json();
  } catch (error) {
    data = undefined;
  }

  if (!schema) {
    return { success: true, response, data: data as unknown };
  }

  const result = schema.safeParse(data);
  if (result.success) {
    return { success: true, response, data: result.data };
  }

  // Debug
  console.error("Failed to parse response", {
    method: options.method || "GET",
    url,
    data,
    error: result.error.issues,
  });

  return {
    success: false,
    response,
    error: new ApiParseError({
      method: options.method || "GET",
      url,
      data,
      error: result.error,
    }),
  };
}
