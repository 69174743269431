import z from "zod";
import { request } from "./request";

// ///////////////////////////////////////////////////////////////////
// ///////// Customer Profile ////////////////////////////////////////
// ///////////////////////////////////////////////////////////////////

// export const customerProfileSchema = z.object({
//   customer: z.object({
//     id: z.string(),
//     name: z.string(),
//     masked_local_cell_phone_number: z.string(),
//   }),
// });

// export type CustomerProfile = z.infer<typeof customerProfileSchema>;

// /**
//  * Fetches the profile of a customer.
//  * @returns
//  */
// export const fetchCustomerProfile = async (customerId: string) =>
//   request(
//     `/customers/${customerId}/profile`,
//     { method: "GET" },
//     customerProfileSchema,
//   );

///////////////////////////////////////////////////////////////////
///////// OPT Delivery ////////////////////////////////////////////
///////////////////////////////////////////////////////////////////

export const DeliverySchema = z.object({
  status: z.string(),
});

export type Delivery = z.infer<typeof DeliverySchema>;

/**
 * Create a one-time password delivery.
 */
export const createOneTimePasswordDelivery = async ({ profileId, cellPhoneNumber }: { profileId : string, cellPhoneNumber: string }) =>
  request(
    '/one_time_password_deliveries',
    {
      method: "POST",
      headers: {
        "accept": "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify({ profile_id: profileId, cell_phone_number: cellPhoneNumber }),
    },
    DeliverySchema,
  );



///////////////////////////////////////////////////////////////////
///////// Token ///////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////

export const tokenSchema = z.object({
  token: z.string(),
});

export type Token = z.infer<typeof tokenSchema>;

/**
 * Create an authentication token.
 */
export const createToken = async ({ customerId }: {customerId: string}) =>
  // TODO: Not sure if we need to provide the customerId.
  request(
    `/customers/${customerId}/tokens`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "accept": "application/json",
      },
    },
    tokenSchema,
  );

///////////////////////////////////////////////////////////////////
///////// Login ///////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////

export const login = async ({ profileId, code }: { profileId : string, code: string }) =>
  request(
    `/login`,
    {
      method: "POST",
      headers: {
        "accept": "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify({ profile_id: profileId, code }),
    },
    tokenSchema,
  );


///////////////////////////////////////////////////////////////////
///////// Fetch Profile ///////////////////////////////////////////
///////////////////////////////////////////////////////////////////

export const profileSchema = z.object({
  id: z.string(),
  account: z.object({
    name: z.string(),
    logo_url: z.string().url().nullable(),
    language: z.string(),
  }),
  customer: z.object({
    id: z.string(),
    name: z.string(),
    cell_phone_number: z.boolean(),
    preferred_language: z.string().nullable(),
  }),
});

export type Profile = z.infer<typeof profileSchema>;

/**
 * Fetches the profile.
 */
export const fetchProfile = async ({ profileId }: {profileId: string}) =>
  request(
    `/profiles/${profileId}`,
    {
      method: "GET",
      headers: {
        "accept": "application/json",
      },
    },
    profileSchema,
  );
